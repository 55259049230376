import { navigate } from 'gatsby'

export const applySearchState = (url: URL, search: string) => {
  const searchParams = new URLSearchParams(search)

  const utmParams = Array.from(searchParams.entries())
    .filter(([param]) => param.toLowerCase().startsWith('utm_'))
    .map(([param, value]) => `${param}=${value}`)
    .join('&')

  const link = `${url.pathname}${url.search}${utmParams ? `&${utmParams}` : ''}`

  navigate(link)
}
